// ==================================================
//  News Tab
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  const newsTabs = document.querySelectorAll(".js-newsTab .news_tab_item");
  const newsTabActiveClass = "news_tab_item-active";
  const newsContents = document.querySelectorAll(".js-newsContent .news_content_list");
  const newsContentActiveClass = "news_content_list-active";
  let newsTabClickNumber = "";
  newsTabs.forEach((newsTab) => {
    newsTab.addEventListener("click", tabSwitch);
  });
  function tabSwitch(event) {
    newsTabs.forEach((newsTab) => {
      newsTab.classList.remove("news_tab_item-active");
    });
    newsContents.forEach((newsContent) => {
      newsContent.classList.remove("news_content_list-active");
    });
    newsTabClickNumber = Array.prototype.indexOf.call(newsTabs, event.target);
    newsTabs[newsTabClickNumber].classList.add("news_tab_item-active");
    newsContents[newsTabClickNumber].classList.add("news_content_list-active");
  }
});
